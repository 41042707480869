import { useLibraryService } from "~/src/services/LibraryService";
import {
  abortNavigation,
  createError,
  defineNuxtRouteMiddleware,
} from "#imports";

export default defineNuxtRouteMiddleware(async (to, _from) => {
  const { useGetLibraryQuery } = useLibraryService();
  if (to.params.id === "new") return true;
  const result = await useGetLibraryQuery(String(to.params.id));

  if (result.error.value)
    return abortNavigation(
      createError({
        statusCode: 500,
        message: result.error.value.message,
      })
    );
});
